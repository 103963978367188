<mat-form-field appearance="{{appearance}}"  class="limitedwidth">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="formCtrl" placeholder="{{label}}"  #singleSelect>
      <mat-option>
        <ngx-mat-select-search 
          [placeholderLabel]=" 'cityIdApp.searchSelect.search' | translate " 
          [noEntriesFoundLabel]=" 'cityIdApp.searchSelect.noProgramsFound' | translate "  
          [formControl]="searchFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngIf="enableNone">({{ 'cityIdApp.global.noselection' | translate }})</mat-option>
      <mat-option *ngFor="let p of filteredPartners | async" [value]="p">{{displayFn(p)}}</mat-option>
    </mat-select>
</mat-form-field>

