import {ResponsiveTableHeader} from '../../util/util/helpers';
import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ITEMS_PER_PAGE} from '../../util/constants/pagination.constants';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-responsivetable',
  templateUrl: './responsivetable.component.html',
  styleUrls: ['./responsivetable.component.scss']
})
export class ResponsivetableComponent implements OnInit, OnChanges {
  @Input() tabledata;
  @Input() tableHeader: Array<ResponsiveTableHeader>;
  @Output() selectedItems = new EventEmitter();
  @Output() editItem = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  @Output() nextItem = new EventEmitter();
  @Output() openDialog = new EventEmitter();
  @Input()  totalItems: number;
  @Output() sortParameter = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  status = true;
  displayItems = [];
  selection = [];
  itemsArray = [];
  pageSize = ITEMS_PER_PAGE;
  reverse = false;
  dataSource: MatTableDataSource<any>;
  predicate: any;
  displayedColumns = [];
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabledata) {
      this.load();
    }
  }

  ngOnInit() {
    this.tableHeader.forEach(element => {
      this.displayedColumns.push(element.key)
    });
    this.dataSource.sort = this.sort;
  } 

  load() {
    if (this.tabledata) {
      this.getRows();
      this.status = false;
    }
  }
  
  permission(key) {
    let header = this.tableHeader.find(k => k.key == key);
    if (header && header.permission) {
      if (typeof header.permission === 'string') {
        return [header.permission];
      }
      return header.permission;
    }
  }

  getRows() {
    this.displayItems = [...this.tabledata];
    this.dataSource = new MatTableDataSource(this.displayItems);
  }

  first() {
    this.paginator.firstPage();
  }

  next(event) {
    this.nextItem.emit(event);
  }

  sortData(event) {
    if (event.direction === 'desc') {
          this.reverse = false;
    } else {
          this.reverse = true;
    }
    this.predicate = event.active;
    const result = this._sort();
    this.sortParameter.emit(result);
  }

  _sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  edit(id) {
    this.editItem.emit(id);
  }

  delete(id) {
    this.deleteItem.emit(id);
  }

  selectChange(event, element) {
    if(event.checked) {
      if ( this.selection.filter( i => i.id === element.id).length < 1 ) {
        this.selection.push(element);
      }
    }
    else {
      this.selection = this.selection.filter(item => item.id !== element.id);
    }
    this.selectedItems.emit(this.selection);
  
  }

  isSelected(element) {
    return this.selection.filter( i => i.id === element.id).length >= 1;
  }

  selectAll(event) {
    if(event.checked) {
      this.displayItems.forEach(element => {
        if ( this.selection.filter( i => i.id === element.id).length < 1 ) {
          this.selection.push(element);
        }
      });
    }
    else {
      this.selection = this.selection.filter(item => this.displayItems.filter( i => i.id === item.id).length < 1);
    }
    this.selectedItems.emit(this.selection);
  }

  isAllSelected() {
    return this.displayItems.filter( i => this.selection.filter( j => j.id === i.id).length >= 1).length === this.displayItems.length;
  }

  openDialogEmit(id) {
    this.openDialog.emit(id);
  }

  getValueFromElement(element : any, spec : string) {
    let subs = spec.split(".");
    let obj = element;
    for (let cnt = 0; cnt < subs.length; cnt++) {
      if (obj == undefined) {
        break;
      }
      obj = obj[subs[cnt]];
    }
    return obj;
  }

  hasRender(d : ResponsiveTableHeader) : boolean {
    return d.render !== undefined && d.render != null;
  }

  enableSelect() : boolean {
    return this.tableHeader.filter( i => i.key === 'select').length > 0;
  }



}
