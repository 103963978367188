import { NeededPermission } from "../../service/permission.service";

export const menus = [
  {
    name: 'How To',
    icon: 'info',
    link: '/manual/manuals/list',
    open: false,
  },
  {
    name: 'Participants',
    icon: 'person',
    link: '/participant',
    permission: [NeededPermission.ParticipantRead],
    open: false,
  },

  {
    name: 'AccessTokens',
    icon: 'credit_card',
    link: '/accesstoken',
    permission: [NeededPermission.AccesstokenRead],
    open: false,
  },
  {
    name: 'Transactions',
    icon: 'money',
    link: '/transaction/transactions',
    permission: [NeededPermission.TransactionRead],
    open: false,
  },
  {
    name: 'Wallets',
    icon: 'account_balance_wallet',
    permission: [NeededPermission.WalletRead],
    link: '/wallet',
    open: false,
  },
  {
    name: 'Partners',
    open: false,
    link: '/provider',
    permission: [NeededPermission.PartnerRead],
    icon: 'grade',
  },
  {
    name: 'Provisioning',
    icon: 'book',
    link: false,
    open: false,
    permission: [NeededPermission.ProvisioningRead],
    sub: [
      {
        name: 'Provisionings',
        open: false,
        link: '/provisioning',
        icon: 'book',
        permission: [NeededPermission.ProvisioningRead]
      },
      {
        name: 'Budgets',
        open: false,
        link: '/provisioningbudget/budgets',
        permission: [NeededPermission.BudgetRead],
        icon: 'credit_card'
      }
    ]
  },
  {
    name: 'Product management',
    icon: 'group_work',
    link: false,
    open: false,
    permission: [NeededPermission.ProductRead],
    sub: [
      {
        name: 'Products',
        open: false,
        link: '/product',
        icon: 'card_giftcard',
      },
      {
        name: 'Sold Products',
        open: false,
        link: '/accesstokenwalletproduct',
        icon: 'credit_card',
      },
    ]
  },
  {
    name: 'Vouchers',
    open: false,
    link: '/vouchers/list',
    queryParams: {program: true},
    permission: [NeededPermission.VoucherRead],
    icon: 'redeem',
  },
  {
    name: 'Targeted Audiences',
    icon: 'groups',
    link: '/audience',
    permission: [NeededPermission.TargetAudienceRead],
    open: false,
  },
  {
    name: 'Newsletters',
    link: '/newsletter',
    icon: 'mail',
    chip: false,
    open: false,
    permission: [NeededPermission.NewsletterRead],

  },
  {
    name: 'Charity management',
    icon: 'spa',
    link: false,
    open: false,
    permission: [NeededPermission.CharityRead],

    sub: [
      {
        name: 'Savings Organisation',
        link: '/savings-organisation',
        icon: 'spa',
        chip: false,
        open: false,
      }
    ]
  },
  {
    name: 'Admin',
    icon: 'view_module',
    open: false,
    link: false,
    sub: [
      {
        name: 'Program Management',
        icon: 'location_city',
        link: false,
        open: false,
        permission: [NeededPermission.OrganisationRead, NeededPermission.ProgramRead],
       
        sub: [
          {
            name: 'Organisations',
            icon: 'location_city',
            link: '/organisation',
            permission: [NeededPermission.OrganisationRead],
            open: false,
          },
          {
            name: 'Programs',
            icon: 'share',
            link: '/program/program',
            permission: [NeededPermission.ProgramRead],
            open: false,
          }
        ]
      },
      {
        name: 'All Partners',
        open: false,
        link: '/all-providers',
        icon: 'grade',
        permission: [NeededPermission.PartnerRead],
      },
      {
        name: 'Access',
        icon: 'person', 
        link: false,
        open: false,
        permission: [NeededPermission.UserRead,NeededPermission.UserRoleRead, NeededPermission.AppClientRead],
        sub: [
          {
            name: 'Users',
            link: '/useraccount/users',
            icon: 'people',
            chip: false,
            open: false,
            permission: [NeededPermission.UserRead],
          },
          {
              name: 'User Roles',
              link: '/useraccount/group',
              icon: 'person',
              chip: false,
              open: false,
              permission: [NeededPermission.UserRoleRead],
            },
          {
            name: "AppClients",
            link: "/appclient",
            icon: "api",
            chip: false,
            open: false,
            permission: [NeededPermission.AppClientRead],
          }
        ]
      },
      {
        name: 'Financial',
        link: false,
        icon: 'euro',
        open: false,
        permission: [NeededPermission.InvoicingRead],
        sub: [
          {
            name: 'Invoices',
            icon: 'receipt',
            permission: [NeededPermission.InvoicingRead],
            link: '/invoice'
          },
          {
            name: 'Invoice Errors',
            icon: 'running_with_errors',
            link: '/invoice-errors'
          },
          {
            name: 'Payers',
            icon: 'account_balance',
            permission: [NeededPermission.InvoicingRead],
            link: '/payer'
          },
          {
            name: 'Financial Entities',
            icon: 'euro',
            permission: [NeededPermission.InvoicingRead],
            link: '/financial-entity'
          },
          {
            name: 'Invoice Templates',
            icon: 'addchart',
            permission: [NeededPermission.InvoicingRead],
            link: '/settings/invoice-settings'
          },
          {
            name: "Relations",
            icon: 'group',
            open: false,
            permission: [NeededPermission.InvoicingRead],
            link: '/relations'
          },
        ]
      },
      {
        name: 'Articles',
        open: false,
        link: '/provisioningbudget/provisioning-articles',
        icon: 'shopping_cart',
        permission: [NeededPermission.ArticleRead],
      },
      {
        name: 'Vouchers',
        open: false,
        link: '/vouchers/list',
        queryParams: {program: false},
        icon: 'redeem',
        permission: [NeededPermission.VoucherRead],
      },
      {
        name: 'AccessTokens',
        link: false,
        opne: false,
        icon: 'credit_card',
        permission: [ NeededPermission.TokenPoolRead, NeededPermission.PrintCards],
        sub: [
          {
            name: 'TokenPools',
            open: false,
            link: '/reserved-token',
            icon: 'add_card',
            permission: [NeededPermission.TokenPoolRead],
          },
          {
            name: 'PrintCards',
            icon: 'print',
            link: '/print-cards',
            open: false,
            permission: [NeededPermission.PrintCards],
          }
        ]
      },
      {
        name: 'Reports',
        icon: 'leaderboard',
        link: false,
        open: false,
        sub : [
          {
            name: 'Transaction Error',
            icon: 'error',
            open: false,
            link: '/transaction-error',
            permission: [NeededPermission.TransactionRead]
          },
          {
            name: 'Generate',
            icon: 'leaderboard',
            link: '/report/generate',
            open: false,
            permission: [NeededPermission.ReportRead]
          },
          {
            name: 'Transaction report',
            icon: 'money',
            link: '/report/transaction-report',
            permission: [NeededPermission.TransactionRead],
            open: false,
          },
  
        ]
      },
      {
        name: 'batch-job',
        icon: 'batch_prediction',
        open: false,
        link: '/batch-job/admin',
        permission: [NeededPermission.BatchJobAdmin],
      },
      {
        name: 'settings',
        icon: 'settings',
        open: false,
        link: '/settings/global-settings',
        permission: [NeededPermission.SettingsRead],
      },
    ]
  },

  {
    name: 'About',
    icon: 'info',
    link: '/about',
    open: false,
  },
];
