import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { IPartner } from "../../model/cityIdcore/partner.model";

@Component({
  selector: "app-simple-partner-selector",
  templateUrl: "./simple-partner-selector.component.html",
  styleUrls: ["./simple-partner-selector.component.scss"],
})
export class SimplePartnerSelectorComponent
  implements OnInit, OnDestroy, OnChanges
{
  protected partners: IPartner[] = [];
  @Input() selectedId: number;
  @Input() readOnly: boolean = false;
  @Input() items: IPartner[];
  @Input() enableNone: boolean = true;
  @Input() withOrgLabel: boolean = true;
  @Input() appearance: string = "outline";
  @Input() label: string = "Partner";
  @Output() onChange = new EventEmitter<IPartner>();

  public formCtrl: FormControl<IPartner> = new FormControl<IPartner>(null);
  public searchFilterCtrl: FormControl<string> = new FormControl<string>("");
  public filteredPartners: ReplaySubject<IPartner[]> = new ReplaySubject<
    IPartner[]
  >(1);
  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  preSelectedId: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.items) {
        if (changes.items.currentValue) {
          this.partners = changes.items.currentValue.sort((a, b) =>
            (a.organisationName + a.name).localeCompare(
              b.organisationName + b.name
            )
          );
          this.filteredPartners.next(this.partners.slice());
        
        }
      }
      if (changes.selectedId) {
        this.preSelectedId = changes.selectedId.currentValue;
        
      }
      this.updateCurrentSelected();
    }
  }
  private updateCurrentSelected() {
    if (this.preSelectedId && this.items && this.items.length > 0) {
      let current = this.items.find(
        (i) => i.id == this.preSelectedId
      );
      this.formCtrl.setValue(current);
    }
  }

  ngOnInit(): void {
    if (this.selectedId) {
      let current = { id: this.selectedId } as IPartner;
      this.formCtrl.setValue(current);
      if (this.readOnly) {
        this.formCtrl.disable();
      }
    }

    this.formCtrl.valueChanges.subscribe((change) => {
      this.onSelected(change);
    });

    if (this.items) {
      this.partners = this.items.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.filteredPartners.next(this.partners.slice()); //set initial program list
    //listen for changes
    this.searchFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.dofilter();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  //Sets the initial value after the filtered programs are loaded initially
  protected setInitialValue() {
    this.filteredPartners
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredPRograms are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: IPartner, b: IPartner) =>
          a && b && a.id === b.id;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected dofilter() {
    if (!this.partners) {
      return;
    }
    // get the search keyword
    let search = this.searchFilterCtrl.value;
    if (!search) {
      this.filteredPartners.next(this.partners.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the programs
    this.filteredPartners.next(
      this.partners.filter((p) => p.name.toLowerCase().indexOf(search) > -1)
    );
  }

  onSelected(newPartner: IPartner) {
    if (this.onChange) {
      this.onChange.emit(newPartner);
    }
  }

  displayFn(p: IPartner): string {
    return p && (p.name ? p.name : "");
  }
}
