import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './component/about/about.component';
import { AppLayoutComponent } from './layout/app-layout.component';
import { NoAccessComponent } from './component/no-access/no-access.component';

const routes: Routes = [

  { path: 'login', loadChildren: () => import('./component/login/login.module').then(m => m.LoginModule) },
  { path: 'request-password', loadChildren: () => import('./component/login/login.module').then(m => m.LoginModule) },
  { path: 'confirm-password', loadChildren: () => import('./component/login/login.module').then(m => m.LoginModule) },
  // App routes goes here here
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: 'participant', pathMatch: 'full' },
      {
        path: 'organisation',
        loadChildren: () => import('./component/organisation/organisation.module')
          .then(m => m.CityIdcoreOrganisationModule)
      },
      {
        path: 'program',
        loadChildren: () => import('./component/program/program.module')
          .then(m => m.CityIdcoreProgramModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./component/report/report.module')
          .then(m => m.ReportModule)
      },
      {
        path: 'participant',
        loadChildren: () => import('./component/participant/participant.module')
          .then(m => m.CityIdcoreParticipantModule)
      },
      {
        path: 'newsletter',
        loadChildren: () => import('./component/newsletter/newsletter.module')
          .then(m => m.CityIdcoreNewsLetterModule)
      },
      {
        path: 'provider',
        loadChildren: () => import('./component/provider/provider.module')
          .then(m => m.CityIdcoreProviderModule)
      },
      {      //added 26/07/2021
        path: 'all-providers',
        loadChildren: () => import('./component/all-providers/all-providers.module')
          .then(m => m.CityIdcoreAllProviderModule)
      },
      {
        path: 'relations',
        loadChildren: () => import('./component/relations/relations.module')
          .then(m => m.RelationsModule)
      },
      {
        path: 'accesstoken',
        loadChildren: () => import('./component/accesstoken/accesstoken.module')
          .then(m => m.CityIdcoreAccessTokenModule)
      },
      {   //added 27/07/2021
        path: 'reserved-token',
        loadChildren: () => import('./component/reserved-token/reserved-token.module')
          .then(m => m.CityIdcoreReservedTokenModule)
      },
      {
        path: 'invoicetext',
        loadChildren: () => import('./shared/component/settings/invoice-values.module')
          .then(m => m.InvoiceValuesModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./component/settings/settings.module')
          .then(m => m.CityIdcoreSettingsModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./component/invoice/invoice.module')
          .then(m => m.CityIdcoreInvoiceModule)
      },
      {
        path: 'financial-entity',
        loadChildren: () => import('./component/financial-entity/financial-entity.module')
          .then(m => m.FinancialEntityModule)
      },
      {
        path: 'invoice-errors',
        loadChildren: () => import('./component/invoice-errors/invoice-errors.module')
          .then(m => m.CityIdcoreInvoiceErrorsModule)
      },
      {
        path: 'transaction',
        loadChildren: () => import('./component/transaction/transaction.module')
          .then(m => m.CityIdcoreTransactionModule)
      },
      {
        path: 'wallet',
        loadChildren: () => import('./component/wallet/wallet.module')
          .then(m => m.CityIdcoreWalletModule)
      },
      {
        path: 'provisioning',
        loadChildren: () => import('./component/provisionings/provisionings.module')
          .then(m => m.CityIdcoreProvisioningsModule)
      },
      {
        path: 'provisioningbudget',
        loadChildren: () => import('./component/provisioning-budget/provisioning-budget.module')
          .then(m => m.CityIdcoreProvisioningBudgetModule)
      },
      {
        path: 'useraccount',
        loadChildren: () => import('./component/useraccount/useraccount.module')
          .then(m => m.CityIdcoreUseraccountModule)
      },
      {
        path: 'appclient',
        loadChildren: () => import("./component/appclient/appclient.module")
          .then(m => m.CityIdcoreAppClientModule)
      },
      {
        path: 'savingrule',
        loadChildren: () => import('./component/savings-transaction-rules/savings-transaction-rules.module')
          .then(m => m.CityIdcoreSavingsTransactionRulesModule)
      },
      {
        path: 'product',
        loadChildren: () => import('./component/product/product.module')
          .then(m => m.CityIdcoreProductModule)
      },
      {
        path: 'accesstokenwalletproduct',
        loadChildren: () => import('./component/accesstoken-wallet-product/access-token-wallet-product.module')
          .then(m => m.CityIdcoreAccessTokenWalletProductModule)
      },
      {
        path: 'savings-organisation',
        loadChildren: () => import('./component/savings-organisation/savings-organisation.module')
          .then(m => m.CityIdcoreSavingsOrganisationModule)
      },
      {
        path: 'savings-goal',
        loadChildren: () => import('./component/savings-goal/savings-goal.module')
          .then(m => m.CityIdcoreSavingsGoalModule)
      },
      {
        path: 'payer',
        loadChildren: () => import('./component/payer/payer.module')
          .then(m => m.PayerModule)
      },
      {
        path: 'transaction-error',
        loadChildren: () => import('./component/transaction-error/transaction-error.module')
        .then(m => m.CityIdcoreTransactionErrorModule)
      },
      {
        path: 'print-cards',
        loadChildren: () => import('./component/print-cards/print-cards.module')
          .then(m => m.PrintCardsModule)
      },
      {
        path: 'vouchers',
        loadChildren: () => import('./component/vouchers/vouchers.module')
          .then(m => m.VouchersModule)
      },
      {
        path: 'manual',
        loadChildren: () => import('./component/manual/manual.module')
        .then(m => m.ManualModule)

      },
      {
        path: 'about',
        component: AboutComponent
      },
     
      {
        path: 'no-access',
        component: NoAccessComponent
      },
      {
        path: 'no-access/:neededPermissions',
        component: NoAccessComponent
      },
      {
        path: 'audience',
        loadChildren: () => import('./component/audience/audience.module').then(m => m.AudienceModule)
      },
      {
        path: 'batch-job',
        loadChildren: () => import('./component/batch-job/batch-job.module').then(m => m.BatchJobModule)
      }
    ]
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
