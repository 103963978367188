import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule, DatePipe} from '@angular/common';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MaterialDesignModule} from './material-design.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {ResponsivetableComponent} from './shared/component/responsivetable/responsivetable.component';
import {AccesstokendialogComponent} from './component/participant/accesstokendialog/accesstokendialog.component';
import {MatListModule} from '@angular/material/list';
import {AlertModule} from './shared/component/alert';
import {AngularIbanModule} from 'angular-iban';
import {ConfirmdialogComponent} from './shared/component/confirmdialog/confirmdialog.component';
import { BlobUploadComponent } from './shared/component/blob-upload/blob-upload.component';
import { BlobDownloadComponent } from './shared/component/blob-download/blob-download.component';
import { TextInputDialogComponent } from './shared/component/text-input-dialog/text-input-dialog.component';
import {ColumnSorterComponent} from 'src/app/shared/pipes/va-mat-table/actions/column-sorter/column-sorter.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ExportComponent } from './shared/component/export/export.component';
import { ProgramSelectorComponent } from './shared/component/program-selector/program-selector.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ProgramSelectorButtonComponent } from './shared/component/program-selector/program-selector-button/program-selector-button.component';
import { ProgramSelectorDialogComponent } from './shared/component/program-selector/program-selector-dialog/program-selector-dialog.component';
import { PercentageFixedComponent } from './shared/component/costs-and-savings/percentage-fixed.component';
import { InvoicingViaComponent } from './shared/component/invoicing/invoicing-via.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MemberGroupComponent } from './shared/component/member-group/member-group.component';
import { MemberGroupDialogComponent } from './shared/component/member-group/member-group-dialog/member-group-dialog.component';
import { AccesstokenWalletsComponent } from './component/accesstoken/accesstoken-wallets/accesstoken-wallets.component';
import { ExtendWalletComponent } from './component/accesstoken/extend-wallet/extend-wallet.component';
import { ExportDialogComponent } from './shared/component/export/export-dialog/export-dialog.component';
import { PartnerPickerDialogComponent } from './shared/component/partner-picker-dialog/partner-picker.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SimpleProgramSelectorComponent } from './shared/component/simple-program-selector/simple-program-selector.component';
import { PartnerArticleValuesComponent } from './component/provisioning-budget/partner-article-values/partner-article-values.component';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { LocalDateAdapter } from './shared/localdate-adapter';
import { LocalDateSelectorComponent } from './shared/component/local-date-selector/local-date-selector.component';

import { UseraccountListBasicComponent } from './shared/component/useraccount-list-basic/useraccount-list-basic.component';
import { ImportFeedbackComponent } from './shared/component/abstract-import-dialog/import-feedback/import-feedback.component';
import { HasPermissionDirective } from './shared/util/util/has-permission.directive';
import { HasEditPermissionDirective } from './shared/util/util/hasEditPermission.directive';
import { HasAllPermissionsDirective } from './shared/util/util/has-all-permissions.directive';
import { OperationLogComponent } from './shared/component/operation-log/operation-log.component';
import { ScrollToDirective } from './shared/util/util/sroll-to-directive';
import { AccesstokenVouchersComponent } from './component/accesstoken/accesstoken-vouchers/accesstoken-vouchers.component';
import { NumberInputDialogComponent } from './shared/component/number-input-dialog/number-input-dialog.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectFilterComponent } from './shared/component/select-filter/select-filter.component';
import { SimplePartnerSelectorComponent } from './shared/component/simple-partner-selector/simple-partner-selector.component';

@NgModule({
    imports: [DragDropModule,
        MaterialDesignModule,
        FlexLayoutModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        RouterModule,
        MatListModule,
        TranslateModule,
        AlertModule,
        RouterModule,
        AngularIbanModule,
        MatTableExporterModule,
        MatSortModule,
        MatTableModule,
        MatSlideToggleModule,
        NgxMatSelectSearchModule,
        InputNumberModule 
    ],
    exports: [
        MaterialDesignModule,
        MatTableExporterModule,
        FlexLayoutModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        RouterModule,
        AlertModule,
        AngularIbanModule,
        ResponsivetableComponent,
        TranslateModule,
        AccesstokendialogComponent,
        BlobUploadComponent,
        BlobDownloadComponent,
        ColumnSorterComponent,
        ExportComponent,
        ProgramSelectorComponent,
        ProgramSelectorButtonComponent,
        ProgramSelectorDialogComponent,
        PercentageFixedComponent,
        InvoicingViaComponent,
        MemberGroupComponent,
        MemberGroupDialogComponent,
        AccesstokenWalletsComponent,
        AccesstokenVouchersComponent,
        ExtendWalletComponent,
        ExportDialogComponent,
        PartnerPickerDialogComponent,
        SimpleProgramSelectorComponent,
        SimplePartnerSelectorComponent,
        PartnerArticleValuesComponent,
        UseraccountListBasicComponent,
        ExportDialogComponent,
        LocalDateSelectorComponent,
        ImportFeedbackComponent,
        HasPermissionDirective,
        HasEditPermissionDirective,
        HasAllPermissionsDirective,
        ScrollToDirective,
        OperationLogComponent
    ],
    declarations: [ResponsivetableComponent, AccesstokendialogComponent, ConfirmdialogComponent, TextInputDialogComponent, NumberInputDialogComponent,
    BlobDownloadComponent, BlobUploadComponent,ColumnSorterComponent, ExportComponent, ProgramSelectorComponent,
    ProgramSelectorButtonComponent, ProgramSelectorDialogComponent,PercentageFixedComponent,InvoicingViaComponent,
    MemberGroupComponent, MemberGroupDialogComponent, AccesstokenWalletsComponent, AccesstokenVouchersComponent,ExtendWalletComponent, ExportDialogComponent,
    PartnerPickerDialogComponent, SimpleProgramSelectorComponent, UseraccountListBasicComponent,
    PartnerArticleValuesComponent, LocalDateSelectorComponent, ImportFeedbackComponent,
    HasPermissionDirective, HasEditPermissionDirective, HasAllPermissionsDirective,ScrollToDirective, OperationLogComponent, NumberInputDialogComponent, SelectFilterComponent, SimplePartnerSelectorComponent
],

    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: LocalDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },

    ],

})
export class SharedModule { }
