import {ToolbarComponent} from './toolbar.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialDesignModule} from '../../../material-design.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SortPipe } from '../../pipes/sort.pipe';
import {TranslateModule} from '@ngx-translate/core';
import { SimpleProgramSelectorComponent } from '../simple-program-selector/simple-program-selector.component';
import { SharedModule } from 'src/app/shared.module';

@NgModule({
  declarations: [
    ToolbarComponent,
    SortPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [
    ToolbarComponent
  ],
  providers: [SortPipe]
})
export class ToolbarModule {
}
